import { useRouter } from 'next/router';
import QRCode from 'qrcode.react';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { PaymentMethod } from '@api/order/types';
import { Button, Dialog, Image, Stack, Transition, Typography } from '@components/common';
import Token from '@constants/token';
import { useOnlineDeliveryPaymentMethodOptions } from '@hooks/orders';
import { Divider } from '@mui/material';
import { useQueryBrandInfo } from '@queries/commons';

interface Props {
  onClose: () => void;
  onCancelPayment?: () => void;
  paymentMethod: PaymentMethod;
  qr: string;
}

const QR_IMAGE_ID = 'image-qr-id';
const QRFormDialog: React.FC<Props> = (props) => {
  const { qr, onClose, paymentMethod, onCancelPayment } = props;

  const open = !!qr;

  const { t } = useTranslation();
  const { data } = useQueryBrandInfo();

  const { query } = useRouter();

  const orderId = query?.orderId;

  const brandLogo = data?.brand?.logoUrl;
  const brandName = data?.brand?.name;

  const downloadQR = useCallback(() => {
    const canvas = document.getElementById(QR_IMAGE_ID) as HTMLCanvasElement;
    const pngUrl = canvas?.toDataURL('image/png')?.replace('image/png', 'image/octet-stream');
    const downloadLink = document.createElement('a');
    downloadLink.href = pngUrl;
    downloadLink.download = `${orderId}-QR.png`;
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  }, [orderId]);

  const handleClose = useCallback(() => {
    onCancelPayment?.();
    onClose();
  }, [onCancelPayment, onClose]);

  const paymentMethodOptions = useOnlineDeliveryPaymentMethodOptions();
  const selectedPaymentMethod = paymentMethodOptions.find((option) => option.type === paymentMethod.type);

  return (
    <Dialog fullScreen open={open} TransitionComponent={Transition}>
      <Stack height={'100%'} padding={'m'} justifyContent={'space-between'}>
        <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
          <Image src={'/logo/qris-horizontal.png'} height={16} width={103} />
          <Image
            height={30}
            width={30}
            src={brandLogo}
            alt={t('general.alt_logo_image', { brandName })}
            objectFit={'contain'}
          />
        </Stack>
        <Stack spacing={'xxl'}>
          <Stack spacing={'xxs'}>
            <Typography textAlign={'center'} size={'m'}>
              {brandName}
            </Typography>
            <Typography textAlign={'center'} size={'xs'}>{`NMID: ${orderId}`}</Typography>
          </Stack>
          <Stack alignItems={'center'}>
            <QRCode id={QR_IMAGE_ID} value={qr} size={225} includeMargin spacing={'10px'} />
          </Stack>
        </Stack>
        <Stack spacing={'xxl'}>
          <Stack spacing={'m'}>
            <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
              <Stack direction={'row'} spacing={'m'} alignItems={'center'}>
                {selectedPaymentMethod?.icon}
                <Typography>{selectedPaymentMethod?.label}</Typography>
              </Stack>
              <Stack
                color={Token.color.uiDarkSecondary}
                borderColor={'uiLightSecondary'}
                borderWidth={'bolder'}
                borderRadius={'default'}
              >
                <Button variant={'contained'} color={'primary'} onClick={downloadQR} borderRadius={'default'}>
                  <Typography size={'xs'}>{t('order.download_qr_label')}</Typography>
                </Button>
              </Stack>
            </Stack>
            <Divider />
          </Stack>

          <Typography size={'xs'}>{t('order.qr_payment_description')}</Typography>
          <Stack spacing={'xs'}>
            <Button color={'secondary'} variant={'text'} onClick={handleClose}>
              <Typography color={'uiDarkSecondary'} textDecoration={'underline'}>
                {t('order.change_payment_method_label')}
              </Typography>
            </Button>
            <Button color={'secondary'} variant={'text'} onClick={handleClose}>
              <Typography color={'uiDarkSecondary'} textDecoration={'underline'}>
                {t('order.cancel_payment_label')}
              </Typography>
            </Button>
          </Stack>
        </Stack>
      </Stack>
    </Dialog>
  );
};

export default QRFormDialog;
